<template>
  <AtomsTextLink to="/" class="text-4xl text-neutral-50 font-bold text-center"><img :src="`/img/logo/rigad-${props.color ?? 'white'}.svg`" class="max-w-[100px] tablet:max-w-[140px] tablet:max-h-[60px] mx-auto desktop:max-w-[175px]" alt="Rigad Logo" :loading="props.loading" /></AtomsTextLink>
</template>
<script setup>

const props = defineProps({
  color: 'white' | 'neutral' | 'black',
  loading: String
})

</script>